import { Box, Button, Grid } from "@mui/material"
import { StyledBox } from "./Interview"
import { Helmet } from "react-helmet"
import { useUser } from "hooks/useUser"
import { useRouter } from "hooks/useRouter"
import { getUserData } from "services/user.service"
import { toast } from "react-toastify"
import MainLayout from "components/layout/MainLayout"
import { useEffect } from "react"
import { STATUS } from "utils/constants"

export const Billing = () => {
    const { user, uniqueId, setUser } = useUser()
    const router = useRouter()

    const shouldBeDisabled = user?.applicant[0]?.billing_information_status
    const email = user?.applicant[0]?.email_id

    const applicationStatus = user?.applicant[0]?.application_form_status
    const videoStatus = user?.applicant[0]?.video_interview_status
    const manualCheck = user?.applicant[0]?.manual_check_status

    useEffect(() => {
        if (user) {
            if (applicationStatus === 0) {
                router.push(`/${uniqueId}`)
            } else if (videoStatus === 0 && applicationStatus === 1) {
                router.push(`/interview/${uniqueId}`)
            } else if (videoStatus === 1 && applicationStatus === 1 && manualCheck !== STATUS.COMPLETED) {
                router.push(`/manual-check/${uniqueId}`)
            } else if (manualCheck === STATUS.COMPLETED && !shouldBeDisabled) {
                router.push(`/billing/${uniqueId}`)
            } else if (shouldBeDisabled) {
                router.push("/congratulations")
            }
        }
    }, [user, shouldBeDisabled])

    useEffect(() => {
        const fetchUser = async () => {
            const resp = await getUserData(uniqueId)
            setUser(resp)
        }
        const interval = setInterval(() => fetchUser(), 3000)
        return () => {
            clearInterval(interval)
        }
    }, [setUser, uniqueId, user])

    const onFinish = () => {
        if (user?.applicant[0]?.billing_information_status === 1) {
            router.push("/congratulations")
        } else {
            toast.error("Please fill the billing form")
        }
    }

    return (
        <MainLayout>
            <Box>
                {email && (
                    <Helmet>
                        <script>
                            {`
                            var myLandbot = new Landbot.Container({
                                container: "#billingLandbot",
                                configUrl: 'https://storage.googleapis.com/landbot.pro/v3/H-2095717-DHFO8PO7OVKJWHSK/index.json',
                                customData: {
                                    email: "${email}"
                                }
                            })`}
                        </script>
                    </Helmet>
                )}
                <StyledBox id="billingLandbot" />
                <Grid
                    sx={{ pt: 3, flexDirection: { xs: "row-reverse", md: "row" } }}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <div />
                    <Button disabled={!shouldBeDisabled} onClick={onFinish} variant="contained">
                        Finish
                    </Button>
                </Grid>
            </Box>
        </MainLayout>
    )
}
