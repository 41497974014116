import { Box, Button, Grid, List, ListItem, Typography } from "@mui/material"
import { StyledBox } from "./Interview"
import { useRouter } from "hooks/useRouter"
import { Helmet } from "react-helmet"

import { useUser } from "hooks/useUser"
import { useEffect, useState } from "react"
import { getUserData } from "services/user.service"
import { toast } from "react-toastify"
import MainLayout from "components/layout/MainLayout"
import { Modal } from "components/Modal"

export const ApplicationForm = () => {
    const { user, uniqueId, setUser } = useUser()
    const [showModal, setShowModal] = useState(false)

    const router = useRouter()

    const shoudBeDisabled = user?.applicant[0].application_form_status
    const email = user?.applicant[0]?.email_id

    useEffect(() => {
        if (user?.applicant[0].application_form_status === 1) {
            router.push(`/interview/${uniqueId}`)
        } else {
            setShowModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const fetchUser = async () => {
            const resp = await getUserData(uniqueId)
            setUser(resp)
        }
        const interval = setInterval(() => fetchUser(), 3000)
        return () => {
            clearInterval(interval)
        }
    }, [setUser, uniqueId, user])

    const onNext = () => {
        if (user?.applicant[0]?.application_form_status === 1) {
            router.push(`/interview/${uniqueId}`)
        } else {
            toast.error("Please fill the application form")
        }
    }

    return (
        <MainLayout>
            <StyledBox>
                {email && (
                    <Helmet>
                        <script>
                            {`var myLandbot = new Landbot.Container({
                                container: "#landbot",
                                configUrl: 'https://storage.googleapis.com/landbot.pro/v3/H-2095455-NANYEIORF732ZR4M/index.json',
                                customData: {
                                    email: "${email}"
                                }
                            })
                        `}
                        </script>
                    </Helmet>
                )}
                <StyledBox id="landbot" />
                <Grid
                    sx={{ pt: 3, flexDirection: { xs: "row-reverse", md: "row" } }}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <div />
                    <Button disabled={!shoudBeDisabled} onClick={onNext} variant="contained">
                        Go to next step
                    </Button>
                </Grid>
            </StyledBox>
            <Modal open={showModal} setOpen={() => console.log("")}>
                <div className="modal-content">
                    <Typography fontWeight={500} paddingBottom={1} color="primary" variant="h5">
                        Welcome to Verification process:
                    </Typography>
                    <Typography>
                        Thank you for your interest in joining our telemedicine platform. To start offering
                        consultations, please complete our straightforward verification process:
                    </Typography>
                    <List sx={{ fontWeight: "light", padding: 0, pl: 2, listStyleType: "disc" }}>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,

                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                Application Form:
                            </Typography>
                            <Typography>
                                Fill out the application form with your professional data to help us learn more about
                                your background and qualifications.
                            </Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,
                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                Video Interview:
                            </Typography>{" "}
                            <Typography>Record answers about your professional experience.</Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,

                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                Background Check:
                            </Typography>
                            <Typography>
                                Submit your ID to verify your identity and enhance platform security.
                            </Typography>
                        </ListItem>
                        <ListItem
                            sx={{
                                display: "list-item",
                                padding: 0,
                                paddingTop: 0.1,

                                lineHeight: 1.5,
                                fontSize: 16,
                            }}
                        >
                            <Typography fontWeight={500} paddingY={1}>
                                License Review:
                            </Typography>
                            <Typography>
                                Our team will review your application and verify your medical license with relevant
                                regulatory bodies to ensure it's current and valid.
                            </Typography>
                        </ListItem>
                    </List>
                    <Typography paddingY={2} color="primary">
                        After your documentation is verified, you will be asked to sign a legal agreement and add your
                        billing information to receive timely payments for your services. Once done, you will receive a
                        confirmation email and gain access our platform
                    </Typography>
                    <Box textAlign="center">
                        <Button variant="contained" onClick={() => setShowModal(false)}>
                            Let's Start
                        </Button>
                    </Box>
                </div>
            </Modal>
        </MainLayout>
    )
}
