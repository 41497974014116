import React from "react"
import $ from "jquery"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepContent from "@mui/material/StepContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import VideoRecorder from "react-video-recorder/lib/video-recorder"
import { Stop } from "@mui/icons-material"
import { colors } from "utils/styles"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import styled from "styled-components"
import { useEffect } from "react"
import { getUserData, interviewQuestions, saveInterview, uploadVideoToBucket, uploadVideoToERP } from "services/user.service"
import { toast } from "react-toastify"
import { useRouter } from "hooks/useRouter"
import { useUser } from "hooks/useUser"

const ActionBox = styled(Box)`
    display: flex;
    width: 50px;
    height: 50px;
    background: #ffffff;
    border: 1px solid lightGray;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    margin-right: 10px;
`

function InterviewQuestions({ videoUrl, setVideoUrl }) {
    const [activeStep, setActiveStep] = React.useState(0)
    const [questions, setQuestions] = React.useState([])
    const [uploadingVideo, setUploadingVideo] = React.useState(false)
    const [videoBlob, setVideoBlob] = React.useState(null)
    const [isRecording, setIsRecording] = React.useState(false)
    const [language, setLanguage] = React.useState(localStorage.getItem("wglang"))

    const { user, uniqueId, setUser } = useUser()
    const router = useRouter()

    useEffect(() => {
        interviewQuestions(user?.applicant[0].video_interview)
            .then(resp => {
                setQuestions(resp.question_table)
            })
            .catch(() => {
                setQuestions([])
            })
    }, [router, user])

    useEffect(() => {
        const fetchUser = async () => {
            await getUserData(uniqueId)
            setLanguage(localStorage.getItem("wglang"))
        }
        const interval = setInterval(() => fetchUser(), 3000)
        return () => {
            clearInterval(interval)
        }
    }, [language, uniqueId])

    useEffect(() => {
        if (isRecording) {
            window.onbeforeunload = function () {
                return true
            }

            return () => {
                window.onbeforeunload = null
            }
        }
    }, [isRecording])

    const handleNext = () => {
        if (isRecording) setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleSubmit = async () => {
        setUploadingVideo(true)
        try {
//            const resp = await uploadVideoToBucket(videoBlob)
//            if (resp.data.status === "success") {
//                setVideoUrl(resp.data.url)
//                const submit = await saveInterview(user?.applicant[0].video_interview, resp.data.url)
//
//                if (submit.status === 200) {
//                    const res = await getUserData(user?.applicant[0]?.unique_id)
//                    setUser(res)
//                    toast.success("Interview submitted successfully")
//                    router.push(`/background-check/${user?.applicant[0]?.unique_id}`)
//                }
//                if (submit.response.status === 500) {
//                    toast.error("Something went wrong while uploading video")
//                    setUploadingVideo(false)
//                    return
//                }
//            }
//            setUploadingVideo(false)
            const resp = await uploadVideoToERP(videoBlob, user?.applicant[0].video_interview)
            if (resp.status === 200) {
                setVideoUrl(resp.data.message.file_url)
                const submit = await saveInterview(user?.applicant[0].video_interview, resp.data.message.file_url)

                if (submit.status === 200) {
                    const res = await getUserData(user?.applicant[0]?.unique_id)
                    setUser(res)
                    toast.success("Interview submitted successfully")
                    router.push(`/background-check/${user?.applicant[0]?.unique_id}`)
                }
                if (submit.response.status === 500) {
                    toast.error("Something went wrong while uploading video")
                    setUploadingVideo(false)
                    return
                }
            }
            setUploadingVideo(false)
        } catch (error) {
            toast.error("Something went wrong while uploading video")
            setUploadingVideo(false)
        }
    }

    const startStopRecordingAction = () => {
        if ($("button[class^='record-button']")) {
            $("button[class^='record-button']").click()
        }
        if ($("button[class^='stop-button']")) {
            $("button[class^='stop-button']").click()
        }
    }

    if (uploadingVideo)
        return (
            <Backdrop open={uploadingVideo} sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )

    return (
        <Box display="flex" flexDirection="column" gap={3}>
            {videoUrl ? (
                <Box borderRadius="100" overflow="hidden">
                    <video
                        style={{
                            borderRadius: "20px",
                        }}
                        width="100%"
                        height="100%"
                        controls
                        src={videoUrl}
                    />
                </Box>
            ) : (
                <Box
                    display="flex"
                    sx={{ marginTop: { xs: 40, md: 15 }, flexDirection: { xs: "column-reverse", md: "row" } }}
                    alignItems="start"
                    gap={4}
                >
                    <Box height={500} maxHeight={500} flexGrow={2} sx={{ width: { xs: 380, md: 800 } }} maxWidth={800}>
                        <VideoRecorder
                            onStartRecording={() => setIsRecording(true)}
                            onStopRecording={() => setIsRecording(false)}
                            constraints={{
                                audio: true,
                                video: true,
                            }}
                            isOnInitially
                            countdownTime={0}
                            dataAvailableTimeout={7000}
                            onRecordingComplete={blob => setVideoBlob(blob)}
                        />
                    </Box>
                    <Stepper
                        sx={{ width: 300, marginTop: { xs: 30, md: 0 } }}
                        activeStep={activeStep}
                        orientation="vertical"
                    >
                        {questions.map((step, idx) => (
                            <Step key={step.idx}>
                                <StepLabel color="primary">
                                    <Typography>Question # {idx + 1}</Typography>
                                </StepLabel>
                                <StepContent>{isRecording && <Typography>{step.question}</Typography>}</StepContent>
                            </Step>
                        ))}

                        <Button
                            disabled={activeStep !== questions.length - 1 || isRecording}
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 4, width: 180, mr: 1 }}
                        >
                            Submit interview
                        </Button>
                    </Stepper>
                </Box>
            )}
            {videoUrl && (
                <Button
                    disabled={activeStep !== questions.length - 1 || isRecording}
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ marginX: "auto" }}
                >
                    Submit interview
                </Button>
            )}
            <Box
                sx={{ width: { xs: 360, md: 800 } }}
                maxWidth={800}
                paddingBottom={10}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={4}
            >
                {!isRecording && activeStep !== questions.length - 1 ? (
                    <Button color="primary" variant="contained" onClick={startStopRecordingAction}>
                        Start Recording
                    </Button>
                ) : isRecording && activeStep === questions.length - 1 ? (
                    <Box>
                        <ActionBox onClick={startStopRecordingAction} size="small" color={colors.red} variant="">
                            <Stop />
                        </ActionBox>
                        <Typography mt={1} ml={1.5} fontSize="small">
                            {" "}
                            Stop
                        </Typography>
                    </Box>
                ) : null}

                {isRecording && activeStep !== questions.length - 1 && (
                    <Box>
                        <ActionBox onClick={handleNext}>&rarr;</ActionBox>
                        <Typography mt={1} ml={1.4} fontSize="small">
                            Next
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default InterviewQuestions
