import axiosInstance from "utils/axiosInstance"
import { setItem } from "./localStorage.service"

const API_URL = "https://api.interview.youpalgroup.com/"
const ERP_API_URL = "https://erp.doktorsjouren.se/"

const interviewQuestions = id =>
    axiosInstance
        .get("/method/erpnext.hr.doctype.video_interview.video_interview.get_video_interview?docname=" + id)
        .then(resp => resp.data.message)

const getCurrentUser = id => axiosInstance.get("/resource/Applicant/" + id).then(resp => resp.data.data)

const getUserData = id => {
    const lang = localStorage.getItem("wglang")

    return axiosInstance
        .post(
            `/method/erpnext.hr.doctype.applicant.applicant.get_applicant_data?unique_id=${id}${
                lang ? `&language=${lang}` : ""
            }`,
        )
        .then(resp => {
            if (resp.data?.message?.applicant?.[0]?.preferred_language) {
                setItem("wglang", resp.data?.message?.applicant?.[0]?.preferred_language)

                if (window.Weglot && !window.Weglot.initialized) {
                    window.Weglot.initialize({
                        api_key: "wg_bc9842a957c13d432b7f0d1bd29db8f73",
                        translate_iframes: [".launchlist-iframe"],
                    })
                } else if (window.Weglot) {
                    window.Weglot.switchTo(resp.data?.message?.applicant?.[0]?.preferred_language)
                }
            }
            return resp.data.message
        })
}

const uploadVideoToBucket = async videoBlob => {
    var formData = new FormData()
    formData.append("fname", (Math.random() + 1).toString(36).substring(2) + ".webm")
    formData.append("data", videoBlob)

    var config = {
        method: "POST",
        url: API_URL + "add-video",
        headers: {
            "Content-Type": "multipart/form-data",
            boundary: "MyBoundary",
        },
        data: formData,
    }

    try {
        const resp = await axiosInstance(config)
        return resp
    } catch (error) {
        return JSON.stringify(error)
    }
}

const uploadVideoToERP = async (videoBlob, video_interview_id) => {
    var formData = new FormData()
    formData.append("file", videoBlob, (Math.random()+1).toString(36).substring(2)+".webm")
    formData.append("is_private", 0)
    formData.append("folder", "Home")
    formData.append("doctype", "Video Interview")
    formData.append("docname", video_interview_id)
    formData.append("fieldname", "video_attachment")

    var config = {
        method: "POST",
        url: ERP_API_URL+"api/method/upload_file",
        headers: {
            "Content-Type": "multipart/form-data, boundary: MyBoundary",
            "Connection": "keep-alive",
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br"
        },
        data: formData,
    }

    try {
        const resp = await axiosInstance(config)
        return resp
    } catch (error) {
        return JSON.stringify(error)
    }
}

const saveInterview = (candidate_id, videoUrl) => {
    let data = {
        docname: candidate_id,
        video_url: videoUrl,
        interview_completed: 1,
    }

    return axiosInstance
        .post("/method/erpnext.hr.doctype.video_interview.video_interview.submit_interview_answer", data)
        .then(response => response)
        .catch(error => error)
}

export { getCurrentUser, getUserData, interviewQuestions, uploadVideoToBucket, uploadVideoToERP, saveInterview }
